<template>
    <div>
        <div class="card d-flex p-0 m-0" style="height: calc(100dvh - 7rem)">     
            <div class="card-header pl-3 pr-3 d-flex flex-column flex-md-row align-content-center justify-content-center align-items-center">
                <h4 class="mb-0"><strong>Export task</strong></h4>
                <div class="flex-fill text-right">
                    <el-button size="mini" class="m-0 mr-2" type="primary"
                               @click="getTaskList">
                        <i class="el-icon-refresh"></i> Refresh
                    </el-button>
                </div>
            </div>
            <VuePerfectScrollbar v-loading="isLoading"
                                 style="height: calc(100dvh - 5rem - 3rem)">
            <div class="pl-3 pr-3 container-fluid flex-fill">
                <div class="row" v-if="elements.length > 0">
                    <div class="col-12 col-md-6" v-for="(element, indexItem) in elements" :key="element.id">
                        <div>
                            <el-card class="box-card">
                                <div>
                                    <div class="d-flex">
                                        <div class="mr-2 d-inline-block" style="color: gray; font-size: 0.75rem; border-radius: 5px; padding: 0.25rem 0.75rem; background-color: rgb(238, 238, 238); border: 1px solid rgb(189, 189, 189);">
                                            # {{indexItem+1}}
                                        </div>
                                        <div class="flex-fill">
                                            <strong>{{element.taskName}}</strong>
                                        </div>
                                        <div>
                                            <el-button size="mini" class="m-0 mr-2" type="primary"
                                                       v-if="element.status == 1"
                                                       @click="downloadFile(element)">
                                                Download file
                                            </el-button>
                                            <el-button size="mini" class="m-0 mr-2" type="danger"
                                                       v-if="element.status == 0"
                                                       @click="cancelTask(element.id)">
                                                Cancel
                                            </el-button>
                                            <el-button size="mini" class="m-0" type="danger"
                                                       v-if="element.status == 1 || element.status == 2"
                                                       @click="removeTask(element.id)">
                                                Remove
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div class="mb-1">
                                        <el-progress :text-inside="true" :stroke-width="26" 
                                                     :percentage="Math.round((element.percentCompleted + Number.EPSILON) * 100) / 100"/>
                                    </div>
                                    <div class="d-flex">
                                        <span class="flex-fill">{{element.createdAt | pretyDateUtc}}</span>
                                        <span v-if="element.status == 1">{{element.exportJobType.fileNameExported}}</span>
                                        <span v-if="element.status == 2">
                                            <strong class="text-danger">Canceled</strong>
                                        </span>
                                        <span v-if="element.status == 0">
                                            <strong class="text-success">Running</strong>
                                        </span>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
                <div v-else
                     class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
                    <div class="align-self-center text-center">
                        <div class="mb-5">
                            <img src="/static/image/no-job-icon.svg" style="opacity: 0.7;" />
                        </div>
                        <h4>
                            <strong class="text-muted">There is no export task created</strong>
                        </h4>
                        <p class="text-muted">All export task will be show here !</p>
                    </div>
                </div>
            </div>
            </VuePerfectScrollbar>
        </div>
    </div>
</template>

<script>
    import exportTaskApi from '@/api/common/exportTask';
    export default {
        data() {
            return {
                intervalUpdate: null,
                elements: [],
            };
        },
        mounted() {
            this.getTaskList();
            this.intervalUpdate = setInterval(() => {
                this.getTaskList();
            }, 3000);
        },
        beforeDestroy() {
            clearInterval(this.intervalUpdate);
        },
        methods: {
            getTaskList() {                
                exportTaskApi.getTaskList().then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.elements = response.data.data;
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            removeTask(taskId) {
                exportTaskApi.removeTask(taskId).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.$message({
                            message: 'Congrats, perform action succeed.',
                            type: 'success'
                        });
                        this.getTaskList();
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            cancelTask(taskId) {
                exportTaskApi.cancelTask(taskId).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.$message({
                            message: 'Congrats, perform action succeed.',
                            type: 'success'
                        });
                        this.getTaskList();
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            downloadFile(element) {
                let host = process.env.VUE_APP_API_HOST.slice(0, -1);
                window.open(host + exportTaskApi.downloadTask(element.id), '_blank');
            },
        }
    }
</script>