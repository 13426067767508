import { exportTaskCnf } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');
export default {
    getTaskList() {
        return axios({
            method: "GET",
            url: `${exportTaskCnf.getTaskList}`,
            withCredentials: true,
        });
    },
    removeTask(taskId) {
        return axios({
            method: "GET",
            url: `${exportTaskCnf.removeTask}/${taskId}`,
            withCredentials: true,
        });
    },
    cancelTask(taskId) {
        return axios({
            method: "GET",
            url: `${exportTaskCnf.cancelTask}/${taskId}`,
            withCredentials: true,
        });
    },
    downloadTask(taskId) {
        return `${exportTaskCnf.downloadTask}/${taskId}`;
    }
};
